/**
 *
 * @param {*} j end of the loop
 * @returns
 */
export function generateDayHourMinutes(j) {
  const result = [];
  for (let i = 0; i <= j; i++) {
    result.push({
      label: i,
      value: i,
    });
  }
  return result;
}

/**
 * Converts a camelCase or PascalCase string to Title Case.
 *
 * @param {string} text - The input string to convert.
 * @returns {string} - The converted Title Case string.
 */
export function convertToTitleCase(text) {
  if (!text || typeof text !== 'string') return '';

  // Match sequences of lowercase letters followed by uppercase letters
  const words = text.match(/([A-Z]?[a-z]+|[A-Z]+)/g);
  if (!words) return text;

  // Capitalize each word
  const titleCase = words.map(word =>
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );

  // Join the words with spaces
  return titleCase.join(' ');
}
