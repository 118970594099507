import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyD3Su2ZN7bL6tNu6KZUf9GKBdMla9AN2gw",
  authDomain: "prep-guardian.firebaseapp.com",
  projectId: "prep-guardian",
  storageBucket: "prep-guardian.firebasestorage.app",
  messagingSenderId: "517858489570",
  appId: "1:517858489570:web:7653a848064dc5e36e1abb",
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
