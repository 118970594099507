import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import moment from "moment";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { EyeIcon, HideIcon } from "../../SvgIcons/allIcons";
import DropDown from "../../components/DropDown";
import Loader from "../../components/Loader/Loader";
import NavigateBack from "../../components/NavigateBack";
import useSlider from "../../hooks/useSlider";
import {
  addCustomer,
  editUser,
  getAllCompany,
  getBrandDetails,
  getCompanyBrandsDropDown,
  getOneCompnay,
  getUserDetails,
} from "../../services/services";
import { toastAlert } from "../../utils/SweetAlert";
import { constant } from "../../utils/constants";
import Sidebar from "../sidebar/Sidebar";
import "./addNewUser.css";
import useDetails from "../../hooks/useDetails";

const AddNewUser = () => {
  const { t } = useTranslation();
  const [searchParam] = useSearchParams();
  const id = searchParam?.get("id");
  const isSlider = useSlider();
  const [showPass, setShowPass] = useState(false);
  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState([]);
  const details = useDetails();

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    setValues,
    setFieldValue,
    setFieldTouched,
    resetForm,
    handleSubmit,
  } = useFormik({
    initialValues: {
      company: {
        value: details?.userCompany?._id,
        label: details?.userCompany?.name,
        domain: details?.userCompany?.uuid,
      },
      brand: "",
      role: constant.ROLES.ADMIN,
      userId: "",
      permissions: {
        can_order_labels: false,
        can_manage_label_templates: false,
        can_add_new_users: false,
        can_upload_and_edit_items: false,
        can_generate_reports: false,
        can_prep_list_or_batch: false,
        can_create_custom_labels: false,
        can_view_analytics: false,
        can_create_task_lists: false,
        can_create_receiving_lists: false,
        can_add_custom_note_on_labels: false,
        can_edit_date_and_time_on_label: false,
        can_send_notification_and_news: false,
        can_add_price_adjustments: false,
        can_create_adhoc_item: false,
        can_download_updates_on_app: false,
        _id: "",
      },
      location: [],
      assignAll: false,
      contactName: "",
      email: "",
      password: "",
      userName: "",
      phoneNumber: "",
    },
    validationSchema: yup.object().shape({
      company: yup.object().shape({
        value: yup.string().required().label(t("user.company")),
      }),
      // brand: yup.object().shape({
      //   value: yup.string().required().label(t("user.brand")),
      // }),
      permissions: yup
        .object()
        .shape({
          can_order_labels: yup.boolean(),
          can_manage_label_templates: yup.boolean(),
          can_add_new_users: yup.boolean(),
          can_upload_and_edit_items: yup.boolean(),
          can_generate_reports: yup.boolean(),
          can_prep_list_or_batch: yup.boolean(),
          can_create_custom_labels: yup.boolean(),
          can_view_analytics: yup.boolean(),
          can_create_task_lists: yup.boolean(),
          can_create_receiving_lists: yup.boolean(),
          can_add_custom_note_on_labels: yup.boolean(),
          can_edit_date_and_time_on_label: yup.boolean(),
          can_send_notification_and_news: yup.boolean(),
          can_add_price_adjustments: yup.boolean(),
          can_create_adhoc_item: yup.boolean(),
          can_download_updates_on_app: yup.boolean(),
        })
        .test("at-least-one-true", t("errorMsg.atleastOneKeyTrue"), (value) => {
          return value && Object.values(value).some((v) => v === true);
        }),
      location: yup.array().min(1).label(t("user.location")),
      contactName: yup
        .string()
        .required()
        .label(t("user.conatactName"))
        .min(2)
        .trim()
        .matches(
          /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
          t("errorMsg.nameErr")
        ),
      email: yup.string().when("role", {
        is: (value) => value !== constant.ROLES.USER,
        then: () => yup.string().required().label(t("user.emailAddress")),
      }),
      password: yup.string().when(["id"], {
        is: () => !id,
        then: () =>
          yup
            .string()
            .required()
            .label(t("user.password"))
            .matches(
              /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/,
              t("resetPassword.strongPass")
            ),
      }),
      userName: yup
        .string()
        .required()
        .label(t("user.userName"))
        .min(3)
        .matches(/^[a-zA-Z0-9.\-_$@*!]{3,30}$/, t("errorMsg.invalidUserName")),
      phoneNumber: yup
        .string()
        .label(t("user.phoneNumber"))
        .when("role", {
          is: (value) => value == constant.ROLES.ADMIN,
          then: () =>
            yup.string().required().min(5).label(t("user.phoneNumber")),
        })
        .test(
          "phone-validate",
          t("errorMsg.invalidPhoneNumber"),
          function (value) {
            if (value?.length > 6) {
              return isValidPhoneNumber(String(value));
            } else {
              return true;
            }
          }
        ),
    }),
    onSubmit: (values) => {
      let number = parsePhoneNumber(String(values?.phoneNumber));
      let body = {
        companyId: values?.company?.value,
        brandId: values?.brand?.brandId,
        role: values?.role,
        userPermissions: {
          can_order_labels: values?.permissions?.can_order_labels,
          can_manage_label_templates:
            values?.permissions?.can_manage_label_templates,
          can_add_new_users: values?.permissions?.can_add_new_users,
          can_upload_and_edit_items:
            values?.permissions?.can_upload_and_edit_items,
          can_generate_reports: values?.permissions?.can_generate_reports,
          can_prep_list_or_batch: values?.permissions?.can_prep_list_or_batch,
          can_create_custom_labels:
            values?.permissions?.can_create_custom_labels,
          can_view_analytics: values?.permissions?.can_view_analytics,
          can_create_task_lists: values?.permissions?.can_create_task_lists,
          can_create_receiving_lists:
            values?.permissions?.can_create_receiving_lists,
          can_add_custom_note_on_labels:
            values?.permissions?.can_add_custom_note_on_labels,
          can_edit_date_and_time_on_label:
            values?.permissions?.can_edit_date_and_time_on_label,
          can_send_notification_and_news:
            values?.permissions?.can_send_notification_and_news,
          can_add_price_adjustments:
            values?.permissions?.can_add_price_adjustments,
          can_create_adhoc_item: values?.permissions?.can_create_adhoc_item,
          can_download_updates_on_app:
            values?.permissions?.can_download_updates_on_app,
        },
        locationIds: values?.location,
        name: values?.contactName,
        assignAll: values?.assignAll,
        username: values?.userName,
        dialCode: number?.countryCallingCode,
        mobile: number?.nationalNumber,
        email: values?.email,
      };
      if (values?.password) body.password = values?.password;

      if (!body?.dialCode) delete body.dialCode;
      if (!body?.email) delete body.email;
      if (!body?.username) delete body.username;

      if (!!id) {
        delete body.companyId;
        delete body.brandId;
        body.userPermissions._id = values?.permissions?._id;
        body.userDetailId = values?.userId;
      }

      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: (body) => (!!id ? editUser(id, body) : addCustomer(body)),
    onSuccess: (resp) => {
      resetForm();
      toastAlert("success", resp?.data?.message);
      navigate("../usermanagement");
    },
  });

  const handleCheckAll = (e) => {
    if (e?.target?.checked) {
      if (!!values?.brand?.value) {
        const allIds = data?.locations?.map((i) => i?._id);
        setFieldValue("location", allIds);
      } else {
        const allIds = companyData?.locations?.map((i) => i?._id);
        setFieldValue("location", allIds);
      }
    } else {
      setFieldValue("location", []);
    }
  };

  const handleCheck = (e, id) => {
    if (e.target.checked && !values?.location?.includes(id)) {
      setFieldValue("location", [...values?.location, id]);
    } else {
      setFieldValue(
        "location",
        values?.location?.filter((i) => i != id)
      );
    }
  };

  const loadBrands = async (search, loadedOptions, { page }) => {
    let resp =
      values?.company?.domain &&
      (await getCompanyBrandsDropDown(
        values?.company?.domain,
        page,
        constant.PER_PAGE_TEN,
        search
      ));
    let array = (await resp?.data?.data?.associatedBrands) ?? [];
    return {
      options: array.map((item) => ({
        value: item?._id,
        label: item?.brand?.name,
        brandId: item?.brandId,
      })),
      hasMore: loadedOptions.length == resp?.data?.data?.total ? false : true,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadCompany = async (search, loadedOptions, { page }) => {
    let resp = await getAllCompany(page, constant.PER_PAGE_TEN, search);
    let array = (await resp?.data?.data?.companies) ?? [];
    return {
      options: array.map((item) => ({
        value: item?._id,
        label: item?.name,
        domain: item?.uuid,
      })),
      hasMore: loadedOptions.length == resp?.data?.data?.total ? false : true,
      additional: {
        page: page + 1,
      },
    };
  };

  const { data } = useQuery({
    queryKey: ["brand-details", values?.brand?.value, values?.company?.domain],
    queryFn: async () => {
      const resp =
        values?.company?.domain &&
        values?.brand?.value &&
        (await getBrandDetails(values?.brand?.value, values?.company?.domain));
      return resp?.data?.data ?? [];
    },
  });

  useQuery({
    queryKey: ["user-details", id],
    queryFn: async () => {
      const resp = !!id && (await getUserDetails(id, true));
      let data = resp?.data?.data;
      if (data) {
        setValues({
          ...values,
          company: {
            value: data?.userCompany?._id,
            label: data?.userCompany?.name,
            domain: data?.userCompany?.uuid,
          },
          brand: {
            value: data?.userDetails?.assosciatedBrand?._id,
            label: data?.userBrand?.name,
            brandId: data?.userDetails?.brandId,
          },
          role: data?.role,
          permissions: {
            can_order_labels: !!data?.userPermissions?.can_order_labels,
            can_manage_label_templates:
              !!data?.userPermissions?.can_manage_label_templates,
            can_add_new_users: !!data?.userPermissions?.can_add_new_users,
            can_upload_and_edit_items:
              !!data?.userPermissions?.can_upload_and_edit_items,
            can_generate_reports: !!data?.userPermissions?.can_generate_reports,
            can_prep_list_or_batch:
              !!data?.userPermissions?.can_prep_list_or_batch,
            can_create_custom_labels:
              !!data?.userPermissions?.can_create_custom_labels,
            can_view_analytics: !!data?.userPermissions?.can_view_analytics,
            can_create_task_lists:
              !!data?.userPermissions?.can_create_task_lists,
            can_create_receiving_lists:
              !!data?.userPermissions?.can_create_receiving_lists,
            can_add_custom_note_on_labels:
              !!data?.userPermissions?.can_add_custom_note_on_labels,
            can_edit_date_and_time_on_label:
              !!data?.userPermissions?.can_edit_date_and_time_on_label,
            can_send_notification_and_news:
              !!data?.userPermissions?.can_send_notification_and_news,
            can_add_price_adjustments:
              !!data?.userPermissions?.can_add_price_adjustments,
            can_create_adhoc_item:
              !!data?.userPermissions?.can_create_adhoc_item,
            can_download_updates_on_app:
              !!data?.userPermissions?.can_download_updates_on_app,
            _id: data?.userPermissions?._id,
          },
          location: data?.userDetails?.locations,
          userId: data?.userDetails?._id,
          contactName: data?.userInfo?.name,
          email: data?.email,
          userName: data?.username,
          assignAll: data?.assignAll,
          phoneNumber: `+${data?.userInfo?.dialCode?.trim()}${
            data?.userInfo?.mobile
          }`,
        });
      }
      return true;
    },
  });

  useQuery({
    queryKey: ["company-details", values?.company?.value],
    queryFn: async () => {
      const resp = await (values?.company?.value &&
        getOneCompnay(values?.company?.value, true, true));
      setCompanyData(resp?.data?.data);
      return true;
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <section
        className={isSlider ? "body-content close" : "body-content open"}
      >
        <Container fluid>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <NavigateBack>
              {!!id ? t("user.editUser") : t("user.newUser")}
            </NavigateBack>

            <button
              className="userBtn btn-theme fontSize18"
              type="button"
              onClick={handleSubmit}
            >
              {id ? t("user.saveChanges") : t("user.addUser")}
            </button>
          </div>
          <div className="customer_Role">
            <Row>
              <Col md={6}>
                <div
                  className="w-100"
                  style={{ cursor: !!id ? "not-allowed" : "default" }}
                >
                  <label htmlFor="name" className="fontSize16 mb-2">
                    {t("user.company")} <span className="text-danger">*</span>
                  </label>
                  <DropDown
                    placeholder={t("user.selectCompany")}
                    id="company"
                    loadOptions={loadCompany}
                    isClearable={false}
                    onChange={(e) => {
                      setFieldValue("company", e);
                      setFieldValue("brand", "");
                    }}
                    isDisabled={true}
                    value={values?.company}
                    onBlur={() => setFieldTouched("company", true)}
                  />
                  <small className="text-danger ms-1">
                    {touched?.company && errors?.company?.value}
                  </small>
                </div>
              </Col>
              <Col md={6}>
                <div
                  className="w-100"
                  style={{ cursor: !!id ? "not-allowed" : "default" }}
                >
                  <label htmlFor="name" className="fontSize16 mb-2">
                    {t("user.brand")}
                  </label>
                  <DropDown
                    placeholder={t("user.selectBrand")}
                    id="brand"
                    depends={values?.company?.domain}
                    loadOptions={loadBrands}
                    isClearable={true}
                    onChange={(e) => {
                      setFieldValue("brand", e);
                      setFieldValue("location", []);
                    }}
                    isDisabled={!!id}
                    value={values?.brand}
                    onBlur={() => setFieldTouched("brand", true)}
                  />
                  <small className="text-danger ms-1">
                    {touched?.brand && errors?.brand?.value}
                  </small>
                </div>
              </Col>

              {/* <div>
                <span className="customerID_Txt fontSize14">
                  {t("user.custId")}
                </span>
                <span className="customerID fontSize14 ms-2">#34567821</span>
              </div> */}

              <div>
                <h3 className="fontSize16">{t("user.selectRole")} </h3>
                <div className="select_Role d-flex align-items-center">
                  <div>
                    <input
                      type="radio"
                      id="test1"
                      name="role"
                      value={constant.ROLES.ADMIN}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={constant.ROLES.ADMIN == values.role}
                    />
                    <label htmlFor="test1" className="position_Label free">
                      {t("user.admin")}
                    </label>
                  </div>
                  <div className="positionLabel_Parent">
                    <input
                      type="radio"
                      id="test2"
                      name="role"
                      value={constant?.ROLES.MANAGER}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={constant.ROLES.MANAGER == values.role}
                    />
                    <label htmlFor="test2" className="position_Label">
                      {t("user.manager")}
                    </label>
                  </div>
                  <div className="positionLabel_Parent">
                    <input
                      type="radio"
                      id="test3"
                      name="role"
                      value={constant.ROLES.USER}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={constant.ROLES.USER == values.role}
                    />
                    <label htmlFor="test3" className="position_Label">
                      {t("user.user")}
                    </label>
                  </div>
                </div>
              </div>

              <div className="customer_Access">
                <div className="form-group">
                  <input
                    type="checkbox"
                    id="assignAll"
                    name="assignAll"
                    value={values?.assignAll}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values?.assignAll === true}
                  />
                  <label htmlFor="assignAll">
                    <span className="fontSize14 ms-2">
                      {t("user.accessToAllFutureLocations")}
                    </span>
                  </label>
                </div>
              </div>
              <div className="customer_Access">
                <h3 className="access_Head fontSize16 mb-3">
                  {t("user.accesses")} <span className="text-danger">*</span>
                </h3>
                <Row>
                  <Col md={4}>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="html1"
                        name="permissions.can_order_labels"
                        value={values?.permissions?.can_order_labels}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values?.permissions?.can_order_labels === true}
                      />
                      <label htmlFor="html1">
                        <span className="fontSize14 ms-2">
                          {t("user.orderLabels")}
                        </span>
                      </label>
                    </div>
                  </Col>
                  <Col md={4}>
                    {" "}
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="html2"
                        name="permissions.can_upload_and_edit_items"
                        value={values?.permissions?.can_upload_and_edit_items}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={
                          values?.permissions?.can_upload_and_edit_items ===
                          true
                        }
                      />
                      <label htmlFor="html2">
                        <span className="fontSize14 ms-2">
                          {t("user.uploadEditItem")}
                        </span>
                      </label>
                    </div>
                  </Col>
                  <Col md={4}>
                    {" "}
                    <div className="form-group ">
                      <input
                        type="checkbox"
                        id="html3"
                        name="permissions.can_create_custom_labels"
                        value={values?.permissions?.can_create_custom_labels}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={
                          values?.permissions?.can_create_custom_labels === true
                        }
                      />
                      <label htmlFor="html3">
                        <span className="fontSize14 ms-2">
                          {t("user.customLabel")}
                        </span>
                      </label>
                    </div>
                  </Col>
                  <Col md={4}>
                    {" "}
                    <div className="form-group ">
                      <input
                        type="checkbox"
                        id="html4"
                        name="permissions.can_create_receiving_lists"
                        value={values?.permissions?.can_create_receiving_lists}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={
                          values?.permissions?.can_create_receiving_lists ===
                          true
                        }
                      />
                      <label htmlFor="html4">
                        <span className="fontSize14 ms-2">
                          {t("user.receivingList")}
                        </span>
                      </label>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form-group ">
                      <input
                        type="checkbox"
                        id="html5"
                        name="permissions.can_send_notification_and_news"
                        value={
                          values?.permissions?.can_send_notification_and_news
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={
                          values?.permissions
                            ?.can_send_notification_and_news === true
                        }
                      />
                      <label htmlFor="html5">
                        <span className="fontSize14 ms-2">
                          {t("user.notificationNews")}
                        </span>
                      </label>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form-group ">
                      <input
                        type="checkbox"
                        id="html6"
                        name="permissions.can_download_updates_on_app"
                        value={values?.permissions?.can_download_updates_on_app}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={
                          values?.permissions?.can_download_updates_on_app ===
                          true
                        }
                      />
                      <label htmlFor="html6">
                        <span className="fontSize14 ms-2">
                          {t("user.downloadUpdates")}
                        </span>
                      </label>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="html7"
                        name="permissions.can_manage_label_templates"
                        value={values?.permissions?.can_manage_label_templates}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={
                          values?.permissions?.can_manage_label_templates ===
                          true
                        }
                      />
                      <label htmlFor="html7">
                        <span className="fontSize14 ms-2">
                          {t("user.labelTemplate")}
                        </span>
                      </label>
                    </div>
                  </Col>
                  <Col md={4}>
                    {" "}
                    <div className="form-group  ">
                      <input
                        type="checkbox"
                        id="html8"
                        name="permissions.can_generate_reports"
                        value={values.permissions?.can_generate_reports}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={
                          values.permissions?.can_generate_reports === true
                        }
                      />
                      <label htmlFor="html8">
                        <span className="fontSize14 ms-2">
                          {t("user.generateReports")}
                        </span>
                      </label>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form-group ">
                      <input
                        type="checkbox"
                        id="html9"
                        name="permissions.can_view_analytics"
                        value={values.permissions?.can_view_analytics}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={
                          values.permissions?.can_view_analytics === true
                        }
                      />
                      <label htmlFor="html9">
                        <span className="fontSize14 ms-2">
                          {t("user.viewAnalytics")}
                        </span>
                      </label>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form-group ">
                      <input
                        type="checkbox"
                        id="html10"
                        name="permissions.can_add_custom_note_on_labels"
                        value={
                          values.permissions?.can_add_custom_note_on_labels
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={
                          values.permissions?.can_add_custom_note_on_labels ===
                          true
                        }
                      />
                      <label htmlFor="html10">
                        <span className="fontSize14 ms-2">
                          {t("user.customeNoteLabel")}
                        </span>
                      </label>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form-group ">
                      <input
                        type="checkbox"
                        id="html11"
                        name="permissions.can_add_price_adjustments"
                        value={values?.permissions?.can_add_price_adjustments}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={
                          values?.permissions?.can_add_price_adjustments ===
                          true
                        }
                      />
                      <label htmlFor="html11">
                        <span className="fontSize14 ms-2">
                          {t("user.priceAdjustment")}
                        </span>
                      </label>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="html12"
                        name="permissions.can_add_new_users"
                        value={values.permissions?.can_add_new_users}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.permissions?.can_add_new_users === true}
                      />
                      <label htmlFor="html12">
                        <span className="fontSize14 ms-2">
                          {t("user.addNewUsers")}
                        </span>
                      </label>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form-group  ">
                      <input
                        type="checkbox"
                        id="html13"
                        name="permissions.can_prep_list_or_batch"
                        value={values.permissions?.can_prep_list_or_batch}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={
                          values.permissions?.can_prep_list_or_batch === true
                        }
                      />
                      <label htmlFor="html13">
                        <span className="fontSize14 ms-2">
                          {t("user.prepListBatch")}
                        </span>
                      </label>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form-group ">
                      <input
                        type="checkbox"
                        id="html14"
                        name="permissions.can_create_task_lists"
                        value={values.permissions?.can_create_task_lists}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={
                          values.permissions?.can_create_task_lists === true
                        }
                      />
                      <label htmlFor="html14">
                        <span className="fontSize14 ms-2">
                          {t("user.taskList")}
                        </span>
                      </label>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form-group ">
                      <input
                        type="checkbox"
                        id="html15"
                        name="permissions.can_edit_date_and_time_on_label"
                        value={
                          values.permissions?.can_edit_date_and_time_on_label
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={
                          values.permissions
                            ?.can_edit_date_and_time_on_label === true
                        }
                      />
                      <label htmlFor="html15">
                        <span className="fontSize14 ms-2">
                          {t("user.editDateTime")}
                        </span>
                      </label>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form-group ">
                      <input
                        type="checkbox"
                        id="html16"
                        name="permissions.can_create_adhoc_item"
                        value={values.permissions?.can_create_adhoc_item}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={
                          values.permissions?.can_create_adhoc_item === true
                        }
                      />
                      <label htmlFor="html16">
                        <span className="fontSize14 ms-2">
                          {t("user.adHocItem")}
                        </span>
                      </label>
                    </div>
                  </Col>
                  <small className="text-danger ms-1">
                    {touched.permissions && errors?.permissions}
                  </small>
                </Row>
              </div>

              <div className="customer_Access">
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                  <h3 className="access_Head fontSize16">
                    {t("user.addLocationForUser")}{" "}
                    <span className="text-danger">*</span>
                  </h3>
                  <button className="addLocationBtn">
                    {" "}
                    {/* <PlusIcon /> <span className="ms-1">{t('user.addLocation')}</span> */}
                  </button>
                </div>
                <div className="locationTable_Parent mt-3">
                  <table className="location_Table w-100 table-responsive">
                    <thead>
                      <tr>
                        <th>
                          <div className="form-group mb-0 d-flex align-items-center">
                            <input
                              type="checkbox"
                              id="html21"
                              checked={
                                !!values?.brand?.value
                                  ? data?.locations?.every((elem) =>
                                      values?.location?.includes(elem?._id)
                                    )
                                  : companyData?.locations?.every((elem) =>
                                      values?.location?.includes(elem?._id)
                                    )
                              }
                              onChange={handleCheckAll}
                            />

                            <label htmlFor="html21">
                              <span className="fontSize16 ms-2">
                                {t("user.name")}
                              </span>
                            </label>
                          </div>
                        </th>
                        <th className="fontSize16">{t("user.location")}</th>
                        <th className="fontSize16">{t("user.added")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!!values?.brand?.value ? (
                        data?.locations && data?.locations?.length > 0 ? (
                          data?.locations?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <div className="form-group mb-0">
                                    <input
                                      type="checkbox"
                                      id={item?._id}
                                      onChange={(e) =>
                                        handleCheck(e, item?._id)
                                      }
                                      checked={values?.location?.includes(
                                        item?._id
                                      )}
                                    />
                                    <label htmlFor={item?._id}>
                                      <span className="fontSize16 ms-2">
                                        {item?.name}
                                      </span>
                                    </label>
                                  </div>
                                </td>
                                <td>
                                  {item?.billingAddress?.fullAddress}{" "}
                                  {item?.billingAddress?.street}{" "}
                                  {item?.billingAddress?.city}{" "}
                                  {item?.billingAddress?.state}{" "}
                                  {item?.billingAddress?.zipCode}{" "}
                                  {item?.billingAddress?.country}
                                </td>
                                <td>{moment(item?.createdAt).format("lll")}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={10} className="text-center">
                              <h4>{t("brand.locationNotFound")}</h4>
                            </td>
                          </tr>
                        )
                      ) : companyData?.locations &&
                        companyData?.locations?.length > 0 ? (
                        companyData?.locations?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div className="form-group mb-0">
                                  <input
                                    type="checkbox"
                                    id={item?._id}
                                    onChange={(e) => handleCheck(e, item?._id)}
                                    checked={values?.location?.includes(
                                      item?._id
                                    )}
                                  />
                                  <label htmlFor={item?._id}>
                                    <span className="fontSize16 ms-2">
                                      {item?.name}
                                    </span>
                                  </label>
                                </div>
                              </td>
                              <td>
                                {item?.billingAddress?.fullAddress}{" "}
                                {item?.billingAddress?.street}{" "}
                                {item?.billingAddress?.city}{" "}
                                {item?.billingAddress?.state}{" "}
                                {item?.billingAddress?.zipCode}{" "}
                                {item?.billingAddress?.country}
                              </td>
                              <td>{moment(item?.createdAt).format("lll")}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={10} className="text-center">
                            <h4>{t("brand.locationNotFound")}</h4>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <small className="text-danger ms-1">
                    {touched.location && errors.location}
                  </small>
                </div>
              </div>

              <form>
                <div>
                  <h3 className="infoTxt fontSize16">{t("user.basicInfo")}</h3>
                  <Row>
                    <Col md={6}>
                      <div className="mt-3">
                        <label htmlFor="contactName" className="companyLabel">
                          {t("user.conatactName")}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="contactName"
                          className="commonInput"
                          placeholder={t("user.enterContactName")}
                          name="contactName"
                          value={values?.contactName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <small className="text-danger ms-1">
                          {touched.contactName && errors.contactName}
                        </small>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mt-3">
                        <label htmlFor="name" className="companyLabel">
                          {t("user.emailAddress")}
                          {values?.role !== constant.ROLES.USER && (
                            <span className="text-danger">*</span>
                          )}
                        </label>
                        <input
                          type="text"
                          id="name"
                          className="commonInput"
                          placeholder={t("user.enterEmailAddress")}
                          name="email"
                          value={values?.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                          // disabled={!!id}
                        />
                        <small className="text-danger ms-1">
                          {touched.email && errors.email}
                        </small>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="position-relative mt-3">
                        <label htmlFor="name" className="companyLabel">
                          {t("user.password")}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type={showPass ? "text" : "password"}
                          className="commonInput"
                          placeholder={t("user.enterPassword")}
                          name="password"
                          value={values?.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                        />
                        {showPass ? (
                          <span
                            className="eye_Icon"
                            onClick={() => {
                              setShowPass(false);
                            }}
                          >
                            <EyeIcon />
                          </span>
                        ) : (
                          <span
                            className="eye_Icon"
                            onClick={() => {
                              setShowPass(true);
                            }}
                          >
                            <HideIcon />
                          </span>
                        )}
                        <small className="text-danger ms-1">
                          {touched.password && errors.password}
                        </small>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mt-3">
                        <label htmlFor="userName" className="companyLabel">
                          {t("user.userName")}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="userName"
                          className="commonInput"
                          placeholder={t("user.enterUserName")}
                          name="userName"
                          value={values?.userName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <small className="text-danger ms-1">
                          {touched.userName && errors.userName}
                        </small>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mt-3">
                        <label htmlFor="name" className="companyLabel">
                          {t("user.phoneNumber")}
                          {values?.role == constant.ROLES.ADMIN && (
                            <span className="text-danger">*</span>
                          )}
                        </label>
                        <div>
                          <PhoneInput
                            defaultCountry="es"
                            value={values?.phoneNumber}
                            onChange={(phone) =>
                              setFieldValue("phoneNumber", phone)
                            }
                            onBlur={() => setFieldTouched("phoneNumber", true)}
                          />
                        </div>
                        <small className="text-danger ms-1">
                          {touched.phoneNumber && errors.phoneNumber}
                        </small>
                      </div>
                    </Col>
                  </Row>
                </div>
              </form>
            </Row>
          </div>
        </Container>
      </section>
      {mutation?.isPending && <Loader />}
    </div>
  );
};

export default AddNewUser;
