import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { constant } from "../../utils/constants";
import Pagination from "../../utils/Pagination";
import { toastAlert } from "../../utils/SweetAlert";
import Sidebar from "../sidebar/Sidebar";
import EditPrinterModal from "./EditPrinterModal";
import { noSpecialChars } from "../../helper/helper";
import useDetails from "../../hooks/useDetails";
import useSlider from "../../hooks/useSlider";
import { getAllPrinters, updatePrinter } from "../../services/services";
import { Search } from "../../SvgIcons/allIcons";

function PrinterManagement() {
  const isSlider = useSlider();
  const { t } = useTranslation();
  const details = useDetails();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(constant.PAGE_ONE);
  const [limit, setLimit] = useState(constant.PER_PAGE_TEN);
  const [showEdit, setShowEdit] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);

  const { data, refetch } = useQuery({
    queryKey: ["printer-list", page, limit, details?.companyUID],
    queryFn: async () => {
      const resp =
        details?.companyUID &&
        (await getAllPrinters(details?.companyUID, page, limit, search));
      return resp?.data?.data ?? [];
    },
  });

  const saveMutation = useMutation({
    mutationFn: ({ id, name }) =>
      updatePrinter(details?.companyUID, id, { name }),
    onSuccess: (resp) => {
      refetch();
      setShowEdit(false);
      setModalDetail({});
      toastAlert("success", resp?.data?.message);
    },
  });

  const handleEditPrinter = (item) => {
    const { _id, name } = item;
    setShowEdit(_id);
    setModalDetail({
      name,
      _id,
    });
  };

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="mainhead font-24">{t("printer.printerManagement")}</h2>
          <div className="d-flex align-items-center gap-2"></div>
        </div>
        <Row>
          <Col xl={12}>
            <div className="sidebarBox mt-3">
              <div className="sidebar-box search-form-wrap mb-0">
                <div className="search-form">
                  <div className="form-group">
                    <span className="iconsearch">
                      <Search />
                    </span>
                    <input
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      onKeyDown={(e) => {
                        noSpecialChars(e);
                        if (e.key == "Enter" && search.trim() !== "") refetch();
                      }}
                      onKeyUp={(e) =>
                        e.target.value == "" &&
                        e.key == "Backspace" &&
                        refetch()
                      }
                      type="text"
                      className="form-control"
                      placeholder={t("globals.search")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="table-responsive tableOut mt-4">
          <table className="table tableDashboard">
            <thead className="thead-dark">
              <tr>
                <th>{t("printer.deviceId")}</th>
                <th>{t("printer.name")}</th>
                <th>{t("printer.printerModel")}</th>
                <th>{t("printer.serialNumber")}</th>
                <th>{t("printer.locationName")}</th>
                <th>{t("printer.address")}</th>
                <th>{t("globals.action")}</th>
              </tr>
            </thead>
            <tbody>
              {data?.printers && data?.printers?.length > 0 ? (
                data?.printers?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item?.deviceId || "--"}</td>
                      <td>{item?.name || "--"}</td>
                      <td>{item?.model || "--"}</td>
                      <td>{item?.serialNumber || "--"}</td>
                      <td>{item?.locationDetails?.name || "--"}</td>
                      <td>
                        {item?.locationDetails?.billingAddress?.fullAddress ||
                          "--"}
                      </td>
                      <td>
                        <img
                          src="/images/Edit.svg"
                          alt="delete"
                          onClick={() => handleEditPrinter(item)}
                          role="button"
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    <h4>{t("printer.notFound")}</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalPages={data?.total_pages}
          total={data?.total}
          length={data?.printers?.length}
        />
        {showEdit && (
          <EditPrinterModal
            show={showEdit}
            modalDetail={modalDetail}
            handleClose={() => setShowEdit(false)}
            handleSave={saveMutation.mutate}
          />
        )}
      </div>
    </div>
  );
}

export default PrinterManagement;
