import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import DropDown from "../../components/DropDown";
import useDetails from "../../hooks/useDetails";
import { addTransaction } from "../../services/services";
import { CrossIcon } from "../../SvgIcons/allIcons";

const PaymentModal = ({ show, handleClose, refetch, setPlanDetails }) => {
  const querclient = useQueryClient();
  const details = useDetails();
  const { t } = useTranslation();
  const [showSuccessful, setShowSuccessful] = useState(false);

  const loadLocations = async () => {
    let array = (await details?.userDetails?.userLocations) ?? [];
    return {
      options: array.map((item) => ({
        value: item?._id,
        label: item?.name,
      })),
      hasMore: false,
    };
  };

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    resetForm,
    setFieldTouched,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: {
      locations: [],
      assignAll: false,
    },
    validationSchema: yup.object().shape({
      locations: yup.array().when("assignAll", {
        is: (value) => !value,
        then: () => yup.array().min(1).label(t("category.location")),
      }),
    }),
    onSubmit: (values) => {
      let body = {
        planId: show?._id,
        assignAll: values?.assignAll,
      };
      if (!values?.assignAll)
        body.locations = values?.locations?.map((i) => i.value);
      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: (body) => addTransaction(body),
    onSuccess: () => {
      setShowSuccessful(true);
      handleClose();
      resetForm();
      refetch();
    },
  });

  const handleCloseSuccess = () => {
    setShowSuccessful(false);
    setPlanDetails(true);
    querclient.invalidateQueries(["user-details"]);
  };

  return (
    <>
      <Modal
        show={!!show}
        onHide={() => {
          handleClose();
          resetForm();
        }}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Header className="newPlan_ModalHeader" closeButton={false}>
          <Modal.Title>Payment</Modal.Title>
          <Button
            variant="link"
            onClick={() => {
              handleClose();
              resetForm();
            }}
            className="close_Btn"
          >
            <CrossIcon />
          </Button>
        </Modal.Header>

        <Modal.Body className="newPlan_ModalBody">
          <form action="">
            <Row>
              {/* <Col md={12}>
                <div className="createPlanform_Child">
                  <label htmlFor="planName" className="companyLabel">
                    Cardholder Name
                  </label>
                  <input
                    type="text"
                    id="planName"
                    className="commonInput"
                    placeholder="Enter Cardholder Name"
                    name="planName"
                  />
                  <small className="text-danger ms-1"></small>
                </div>
              </Col>
              <Col md={12}>
                <div className="createPlanform_Child">
                  <label htmlFor="price" className="companyLabel">
                    Card Number
                  </label>
                  <input
                    type="number"
                    id="price"
                    className="commonInput"
                    placeholder="Enter Card Number"
                    name="price"
                  />
                  <small className="text-danger ms-1"></small>
                </div>
              </Col>
              <Col md={6}>
                <div className="createPlanform_Child">
                  <label htmlFor="name" className="companyLabel">
                    Expired Date
                  </label>
                  <input
                    type="date"
                    id="price"
                    className="commonInput"
                    placeholder="Enter Card Number"
                    name="price"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="createPlanform_Child">
                  <label htmlFor="name" className="companyLabel">
                    cvv
                  </label>
                  <input
                    type="number"
                    id="price"
                    className="commonInput"
                    placeholder="cvv"
                    name="price"
                    min={1}
                  />
                </div>
              </Col> */}

              <Col md={12}>
                <div className="mt-3">
                  <label htmlFor="name" className="companyLabel">
                    {t("category.location")}
                    <span className="text-danger">*</span>
                  </label>
                  <DropDown
                    placeholder={t("category.enterLocation")}
                    id="locations"
                    loadOptions={loadLocations}
                    isClearable={false}
                    defaultOptions={true}
                    isDisabled={values?.assignAll}
                    isMulti={true}
                    onChange={(e) => setFieldValue("locations", e)}
                    value={values?.locations}
                    onBlur={() => setFieldTouched("locations", true)}
                  />

                  <small className="text-danger ms-1">
                    {touched.locations && errors.locations}
                  </small>
                </div>
              </Col>
              <Col md={12}>
                <div className="customer_Access">
                  <div className="access_Child">
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="assignAll"
                        name="assignAll"
                        value={values?.assignAll}
                        checked={values?.assignAll}
                        onChange={(e) => {
                          handleChange(e);
                          if (e.target.checked) {
                            setFieldValue("locations", []);
                            setFieldTouched("locations", false);
                          }
                        }}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="assignAll">
                        <span className="fontSize14 ms-2">
                          {t("category.applyToAllfutureLocations")}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </Modal.Body>

        <Modal.Footer className="newPlan_ModalFooter">
          <Button
            variant="secondary"
            className="userBtn btn-theme planModalFooter_Btn"
            onClick={handleSubmit}
            type="button"
          >
            PAY ${show?.unitAmount}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Start successfull Plan Modal */}

      <Modal
        show={showSuccessful}
        onHide={() => {
          handleCloseSuccess();
        }}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Header
          className="successfull_modalHeader newPlan_ModalHeader"
          closeButton={false}
        >
          <Button
            variant="link"
            onClick={() => {
              handleCloseSuccess();
            }}
            className="close_Btn"
          >
            <CrossIcon />
          </Button>
        </Modal.Header>

        <Modal.Body className=".successfull_modalBody">
          <div className="text-center mb-3">
            <img src="/images/done.svg" alt="img" />
          </div>
          <h2 className="success-heading">Your payment is successful!</h2>
        </Modal.Body>
      </Modal>

      {/* End successfull Plan Modal */}
    </>
  );
};

export default PaymentModal;
