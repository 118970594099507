import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import useSlider from "../../hooks/useSlider";
import { deactivatePlans, getPlans } from "../../services/services";
import { CheckMark } from "../../SvgIcons/allIcons";
import { convertToTitleCase } from "../../utils/common";
import Sidebar from "../sidebar/Sidebar";
import "./paymentAndBilling.css";
import PaymentModal from "./PaymentModal";
import useDetails from "../../hooks/useDetails";
import { calculateNextDueDate } from "../../helper/helper";
import moment from "moment";
import NavigateBack from "../../components/NavigateBack";
import { constant } from "../../utils/constants";
import { toastAlert } from "../../utils/SweetAlert";

const PaymentAndBilling = () => {
  const isSlider = useSlider();
  const details = useDetails();
  const querclient = useQueryClient();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [planDetails, setPlanDetails] = useState(
    details?.activePlans?.length > 0 ? true : false
  );

  const { data, refetch } = useQuery({
    queryKey: ["plan-list"],
    queryFn: async () => {
      const resp = await getPlans();
      return resp?.data?.data ?? {};
    },
  });

  const deactivatePlan = useMutation({
    mutationFn: (id) =>
      deactivatePlans(id, {
        status: "not_active",
      }),

    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      querclient.invalidateQueries(["user-details"]);
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <section
        className={isSlider ? "body-content close" : "body-content open"}
      >
        {planDetails ? (
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h2 className="main-heading fontSize24">Current Plan</h2>
              <button
                className="upgradeBtn btn-theme fontSize18"
                type="button"
                onClick={() => setPlanDetails(false)}
              >
                UPGRADE PLAN
              </button>
            </div>
            <Row>
              {details?.activePlans && details?.activePlans?.length > 0 ? (
                details?.activePlans?.map((item, index) => {
                  return (
                    <Col md={12} key={index}>
                      <div className="plan-parent">
                        <div className="current-card">
                          <div className="current-parent-upr">
                            <div className="current-parent-inner w-100">
                              <div className="d-flex justify-content-between align-items-center w-100">
                                <div>
                                  <h3 className="add-month freeTxt fontSize24 text-center">
                                    ${item?.planDetails?.unitAmount}{" "}
                                    <span className="free-small-text">
                                      /{item?.planDetails?.interval}
                                    </span>
                                  </h3>
                                </div>
                                {item?.status == constant.STATUS_CODE.ACTIVE ? (
                                  <button
                                    className="userBtn btn-theme"
                                    type="button"
                                    onClick={() =>
                                      deactivatePlan.mutate(item?._id)
                                    }
                                  >
                                    Deactivate
                                  </button>
                                ) : (
                                  <h3 className="add-month freeTxt fontSize24 text-center free-small-text text-danger">
                                    Deactivated
                                  </h3>
                                )}
                              </div>
                              <p className="currentPlanText">
                                Your next payment is to be charged on{" "}
                                {moment(
                                  calculateNextDueDate({
                                    createdAt: item?.createdAt,
                                    interval: item?.planDetails?.interval,
                                  })
                                ).format("ll")}
                              </p>
                            </div>
                          </div>
                          <div className="includes_Parent">
                            <h3 className="includerTxt fontSize14">
                              INCLUDES:
                            </h3>
                            {item?.planDetails?.addOns?.map((i, ind) => {
                              return (
                                <p className="includes_Data" key={ind}>
                                  <CheckMark />{" "}
                                  <span className="fontSize14">
                                    {convertToTitleCase(i)}
                                  </span>
                                </p>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })
              ) : (
                <Col md={12}>
                  <div className="plan-parent p-5">
                    <div className="current-card text-center">
                      <h4>No Active Plan Found</h4>
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        ) : (
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <NavigateBack handleClick={() => setPlanDetails(true)}>
                Choose Your Plan
              </NavigateBack>
            </div>
            <div className="plan-parent">
              <Row>
                {data?.plans && data?.plans?.length > 0 ? (
                  data?.plans?.map((item, index) => {
                    let activePlan = details?.activePlans
                      ?.map((i) => i?.planId)
                      ?.includes(item?._id);
                    return (
                      <Col md={4} key={index}>
                        <div className="plan-card">
                          <div className="plan-parent-upr">
                            <h3 className="add-month freeTxt fontSize24 text-center">
                              ${item?.unitAmount}
                              <span className="free-small-text">
                                /{item?.interval}
                              </span>
                            </h3>

                            <button
                              className={
                                activePlan
                                  ? "currentBtn  btn-theme fontSize18"
                                  : "currentBtn StartBtn  btn-theme fontSize18"
                              }
                              onClick={() => setShow(item)}
                              disabled={activePlan}
                              type="button"
                            >
                              {activePlan ? "CURRENT PLAN" : "START NOW"}
                            </button>
                          </div>
                          <div className="includes_Parent">
                            <h3 className="includerTxt fontSize14">
                              INCLUDES:
                            </h3>
                            {item?.addOns?.map((i, ind) => {
                              return (
                                <p className="includes_Data" key={ind}>
                                  <CheckMark />{" "}
                                  <span className="fontSize14">
                                    {convertToTitleCase(i)}
                                  </span>
                                </p>
                              );
                            })}
                          </div>
                        </div>
                      </Col>
                    );
                  })
                ) : (
                  <Col md={12}>
                    <h3 className="add-month freeTxt fontSize24 text-center">
                      Plans Not Found
                    </h3>
                  </Col>
                )}
              </Row>
            </div>
          </div>
        )}
      </section>

      {/* Start Create New Plan Modal */}
      <PaymentModal
        show={show}
        handleClose={handleClose}
        refetch={refetch}
        setPlanDetails={setPlanDetails}
      />
    </div>
  );
};

export default PaymentAndBilling;
