import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CrossIcon } from "../../SvgIcons/allIcons";

const EditPrinterModal = ({ show, modalDetail, handleClose, handleSave }) => {
  const { t } = useTranslation();
  const [name, setName] = useState(modalDetail?.name ? modalDetail?.name : "");

  return (
    <Modal
      show={!!show}
      onHide={handleClose}
      centered
      dialogClassName="custom-modal"
    >
      <Modal.Header className="deleteUser_ModalHeader" closeButton={false}>
        <Modal.Title>{t("printer.editPrinter")}</Modal.Title>
        <Button variant="link" onClick={handleClose} className="close_Btn">
          <CrossIcon />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <div className="mt-2">
              <label htmlFor="name" className="companyLabel">
                {t("printer.name")} <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="commonInput"
                placeholder={t("printer.enterPrinterName")}
              />
              {/* <small className="text-danger ms-1">
                {touched.name && errors.name}
              </small> */}
            </div>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer className="deleteUser_ModalFooter">
        <Button
          variant="secondary"
          className="userBtn btn-theme"
          type="button"
          onClick={() => handleSave({ id: show, name })}
        >
          {t("globals.saveChanges")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditPrinterModal;
