export const constant = Object.freeze({
  /*******************Roles******************/
  ROLES: {
    SUPER_ADMIN: "superadmin",
    ADMIN: "admin",
    MANAGER: "manager",
    USER: "user",
  },
  ADMIN_EMAIL: "superadmin@yopmail.com",

  PER_PAGE_TEN: 10,
  PER_PAGE_TWENTY: 20,
  PAGE_ONE: 1,

  PERMISSIONS: {
    CAN_ORDER_LABELS: "can_order_labels",
    CAN_MANAGE_LABEL_TEMPLATES: "can_manage_label_templates",
    CAN_ADD_NEW_USERS: "can_add_new_users",
    CAN_UPLOAD_AND_EDIT_ITEMS: "can_upload_and_edit_items",
    CAN_GENERATE_REPORTS: "can_generate_reports",
    CAN_PREP_LIST_OR_BATCH: "can_prep_list_or_batch",
    CAN_CREATE_CUSTOM_LABELS: "can_create_custom_labels",
    CAN_VIEW_ANALYTICS: "can_view_analytics",
    CAN_CREATE_TASK_LISTS: "can_create_task_lists",
    CAN_CREATE_RECEIVING_LISTS: "can_create_receiving_lists",
    CAN_ADD_CUSTOM_NOTE_ON_LABELS: "can_add_custom_note_on_labels",
    CAN_EDIT_DATE_AND_TIME_ON_LABEL: "can_edit_date_and_time_on_label",
    CAN_SEND_NOTIFICATION_AND_NEWS: "can_send_notification_and_news",
    CAN_ADD_PRICE_ADJUSTMENTS: "can_add_price_adjustments",
    CAN_CREATE_ADHOC_ITEM: "can_create_adhoc_item",
    CAN_DOWNLOAD_UPDATES_ON_APP: "can_download_updates_on_app",
  },

  DATA_LIST: {
    PREP_LIST: "prepList",
    TASK_LIST: "taskList",
    RECEIVING_LIST: "receivingList",
  },

  IMAGE_TYPE: {
    QR: "qrcode",
    BAR: "barcode",
  },

  NUMBER_ONLY: /^[0-9\b]+$/,

  ORDER_BY: {
    ASC: "ASC",
    DESC: "DESC",
  },

  COMPANY_HAVING_LOCATIONS: {
    TRUE: "true",
    FALSE: "false",
  },

  LABEL_TYPE: {
    temper_evident_2x4: "temper_evident_2x4",
    temper_evident_2x6: "temper_evident_2x6",
    circle_2: "circle_2",
    day_of_week_2x1: "day_of_week_2x1",
    day_of_week_2x2: "day_of_week_2x2",
  },

  FILE_ACTION: {
    DOWNLOAD: "Download Sample",
    IMPORT: "Import",
    EXPORT: "Export",
  },

  ADDONS: [
    { label: "Product Waste Tracking", value: "productWasteTracking" },
    { label: "Temperature Monitoring", value: "temperatureMonitoring" },
  ],

  TRANSACTIONS_TYPE: {
    TRANSACTION: "transaction",
    ORDER: "order",
  },

  TRANSACTION_STATUS: {
    SUCCESS: "succeeded",
    FAILED: "failed",
    PENDING: "pending",
  },

  NOTIFICATION_TYPE: {
    EMAIL: "email",
    PUSH: "push",
  },

  STATUS_CODE: {
    ACTIVE: "active",
  },
});
