import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaDownload, FaFileExport, FaFileImport } from "react-icons/fa";
import { Link } from "react-router-dom";
import DeleteModal from "../../components/DeleteModal";
import useDetails from "../../hooks/useDetails";
import useSlider from "../../hooks/useSlider";
import {
  deleteCatergory,
  downloadCategoryExcel,
  exportCategoryExcel,
  getCategory,
  handleImportCatergory,
} from "../../services/services";
import { constant } from "../../utils/constants";
import Pagination from "../../utils/Pagination";
import { toastAlert } from "../../utils/SweetAlert";
import Sidebar from "../sidebar/Sidebar";
import FileModal from "../../components/FileModal";
import { downloadFile } from "../../helper/helper";

const CategoryList = () => {
  const isSlider = useSlider();
  const { t } = useTranslation();
  const details = useDetails();
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(constant.PAGE_ONE);
  const [limit, setLimit] = useState(constant.PER_PAGE_TEN);
  const [showDelete, setShowDelete] = useState(false);

  const { data, refetch } = useQuery({
    queryKey: ["category-list", page, limit],
    queryFn: async () => {
      const resp = await getCategory(details?.companyUID, page, limit);
      return resp?.data?.data;
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => deleteCatergory(details?.companyUID, id),
    onSuccess: (resp) => {
      refetch();
      setShowDelete(false);
      toastAlert("success", resp?.data?.message);
    },
  });

  const fileUploadMutation = useMutation({
    mutationFn: ({ formData, domain }) =>
      handleImportCatergory(formData, domain),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      refetch();
    },
  });

  const handleAction = async (data) => {
    try {
      if (data?.type === constant.FILE_ACTION.IMPORT) {
        const formData = new FormData();
        formData.append("file", data?.file);
        fileUploadMutation.mutate({ formData, domain: details?.companyUID });
      } else {
        const domain = details?.companyUID;
        const response =
          data?.type === constant.FILE_ACTION.EXPORT
            ? await exportCategoryExcel(domain)
            : await downloadCategoryExcel(domain);

        downloadFile(response?.data, `${domain}_category.xlsx`);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setShow(false);
    }
  };

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <div className="d-flex align-items-center justify-content-between">
            <h2 className="mainhead font-24">{t("category.categories")}</h2>

            <div className="d-flex align-items-center gap-2">
              <button
                className="userBtn btn-theme me-2 p-3"
                onClick={() => {
                  setShow({
                    company: details?.companyUID,
                    type: constant.FILE_ACTION.DOWNLOAD,
                  });
                }}
                title="Download Sample"
              >
                <FaDownload />
              </button>

              <label
                className="userBtn btn-theme me-2 p-3"
                role="button"
                htmlFor="import-catergory"
                title="Import"
              >
                <FaFileImport />
              </label>
              <button
                className="userBtn btn-theme me-2 p-3"
                title="Export"
                onClick={() => {
                  if (!data?.categories?.length) {
                    toastAlert("error", "Category not found to export");
                    return;
                  }
                  setShow({
                    company: details?.companyUID,
                    type: constant.FILE_ACTION.EXPORT,
                  });
                }}
              >
                <FaFileExport />
              </button>
              <input
                type="file"
                className="d-none"
                id="import-catergory"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={(e) => {
                  if (
                    e.target.files[0]?.type !==
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  ) {
                    toastAlert("error", t("errorMsg.xlsxFormat"));
                    return;
                  }
                  setShow({
                    company: details?.companyUID,
                    type: constant.FILE_ACTION.IMPORT,
                    file: e.target.files[0],
                  });
                }}
                onClick={(e) => {
                  e.target.value = null;
                }}
              />
              <Link to="../addcategory" className="userBtn btn-theme">
                {t("globals.addNew")}
              </Link>
            </div>
          </div>
        </Row>
        <div className="table-responsive tableOut mt-4">
          <table className="table tableDashboard">
            <thead className="thead-dark">
              <tr>
                <th>#</th>
                <th>{t("category.categoryIcon")}</th>
                <th>{t("category.categoryName")}</th>
                <th>{t("category.categoryNameSpanish")}</th>
                <th>{t("category.location")}</th>
                <th>{t("globals.action")}</th>
              </tr>
            </thead>
            <tbody>
              {data?.categories && data?.categories?.length > 0 ? (
                data?.categories?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{(page - 1) * limit + (index + 1)}</td>
                      <td>
                        <img
                          src={item?.icons ? item?.icons : "/images/dummy.jpg"}
                          alt="cat-icon"
                          width={50}
                          height={50}
                        />
                      </td>
                      <td>{item?.en_name ? item?.en_name : "N/A"}</td>
                      <td>{item?.es_name ? item?.es_name : "N/A"}</td>
                      <td>
                        {item?.assignAll
                          ? t("globals.allLocations")
                          : item?.locations?.map((i) => i.name)?.join(", ")}
                      </td>
                      <td>
                        <Link to={`../addcategory?id=${item?._id}`}>
                          <img
                            src="/images/Edit.svg"
                            className="img-fluid actionIcon"
                          />
                        </Link>
                        <img
                          src="/images/Delete.svg "
                          className="img-fluid actionIcon"
                          onClick={() => setShowDelete(item?._id)}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    <h4>{t("category.notFound")}</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalPages={data?.total_pages}
          total={data?.total}
          length={data?.categories?.length}
        />
        <DeleteModal
          show={showDelete}
          handleClose={() => setShowDelete(false)}
          handleDelete={deleteMutation.mutate}
        />
        <FileModal
          show={show}
          handleClose={() => setShow(false)}
          handleAction={handleAction}
        />
      </div>
    </div>
  );
};

export default CategoryList;
