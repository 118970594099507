import { useMutation } from "@tanstack/react-query";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { logout } from "../redux/features/authSlice";
import { logOut } from "../services/services";
import { CrossIcon } from "../SvgIcons/allIcons";

const LogoutModal = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const logOutMutation = useMutation({
    mutationFn: () => logOut(),
    onSuccess: () => {
      dispatch(logout());
      // localStorage.clear();
      handleClose();
    },
  });

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      dialogClassName="custom-modal"
    >
      <Modal.Header className="deleteUser_ModalHeader" closeButton={false}>
        <Modal.Title>{t("logout.cnfMsg")}</Modal.Title>
        <Button variant="link" onClick={handleClose} className="close_Btn">
          <CrossIcon />
        </Button>
      </Modal.Header>

      <Modal.Footer className="deleteUser_ModalFooter">
        <Button
          variant="secondary"
          className="userBtn btn-theme"
          onClick={() => {
            logOutMutation.mutate();
          }}
        >
          {t("logout.yesLogout")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LogoutModal;
