import { useMutation, useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CheckPermission from "../../components/CheckPermission";
import DeleteModal from "../../components/DeleteModal";
import useDetails from "../../hooks/useDetails";
import useSlider from "../../hooks/useSlider";
import { deleteItem, getItems } from "../../services/services";
import { constant } from "../../utils/constants";
import Pagination from "../../utils/Pagination";
import Sidebar from "../sidebar/Sidebar";
import { toastAlert } from "../../utils/SweetAlert";

const ItemsList = () => {
  const isSlider = useSlider();
  const { t } = useTranslation();
  const details = useDetails();
  const [page, setPage] = useState(constant.PAGE_ONE);
  const [limit, setLimit] = useState(constant.PER_PAGE_TEN);
  const [showDelete, setShowDelete] = useState(false);

  const { data, refetch } = useQuery({
    queryKey: ["item-list", page, limit],
    queryFn: async () => {
      const resp = await getItems(page, limit, details?.companyUID);
      return resp?.data?.data;
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => deleteItem(id, details?.companyUID),
    onSuccess: (resp) => {
      refetch();
      setShowDelete(false);
      toastAlert("success", resp?.data?.message);
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row className="align-items-center">
          <Col xl={6}>
            <h2 className="mainhead font-24">{t("item.items")}</h2>
          </Col>
          <Col className="text-end" xl={6}>
            <button className="userBtn btn-theme me-2">
              {t("globals.export")}
            </button>
            <CheckPermission
              permission={constant.PERMISSIONS.CAN_UPLOAD_AND_EDIT_ITEMS}
            >
              <Link className="userBtn btn-theme" to="../add-item">
                {t("globals.addNew")}
              </Link>
            </CheckPermission>
          </Col>
        </Row>
        <div className="table-responsive tableOut mt-4">
          <table className="table tableDashboard">
            <thead className="thead-dark">
              <tr>
                <th>{t("item.itemName")}</th>
                <th>{t("item.itemNameSpanish")}</th>
                <th>{t("item.category")}</th>
                <th>{t("item.applyToLocation")}</th>
                <th>{t("item.itemSellPrice")}</th>
                <th>{t("item.expiration")}</th>
                <th>{t("globals.action")}</th>
              </tr>
            </thead>
            <tbody>
              {data?.items && data?.items?.length > 0 ? (
                data?.items?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item?.en_name}</td>
                      <td>{item?.es_name ? item?.es_name : "N/A"}</td>
                      <td>
                        {" "}
                        {i18next?.language == "es"
                          ? item?.categoryDetails?.es_name
                          : item?.categoryDetails?.en_name}
                      </td>
                      <td>
                        {item?.assignAll
                          ? t("globals.allLocations")
                          : item?.locations?.map((i) => i.name)?.join(", ")}
                      </td>
                      <td>{item?.additionalInfo?.sellingPrice}</td>
                      <td>
                        <p className="greenBox">{`${item?.expirationTime?.days}d:${item?.expirationTime?.hours}h:${item?.expirationTime?.mins}m`}</p>
                      </td>
                      <td>
                        <Link to={`../item-details/${item?._id}`}>
                          <img
                            src="/images/eye.svg"
                            className="img-fluid actionIcon"
                          />
                        </Link>
                        <CheckPermission
                          permission={
                            constant.PERMISSIONS.CAN_UPLOAD_AND_EDIT_ITEMS
                          }
                        >
                          <Link
                            to={`../add-item?id=${item?._id}`}
                            role="button"
                          >
                            <img src="/images/Edit.svg" alt="" />
                          </Link>
                          <img
                            role="button"
                            src="/images/Delete.svg"
                            className="img-fluid actionIcon"
                            onClick={() => setShowDelete(item?._id)}
                          />
                        </CheckPermission>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    <h4>{t("item.notFound")}</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalPages={data?.total_pages}
          total={data?.total}
          length={data?.items?.length}
        />
      </div>
      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={deleteMutation.mutate}
      />
    </div>
  );
};

export default ItemsList;
